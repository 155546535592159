html {
  height: 100%;
  min-width: 319px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#appContainer {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: scroll;
}

.material-icons {
  font-family: 'Material Icons', system-ui;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.MuiTypography-body1 a:link, .MuiTypography-body1 a:hover, .MuiTypography-body1 a:visited,
.MuiTypography-body2 a:link, .MuiTypography-body2 a:hover, .MuiTypography-body2 a:visited {
  text-decoration: none;
  color: rgb(105,105,105);
}

.MuiTypography-body1 a:hover,
.MuiTypography-body2 a:hover {
  text-decoration: underline;
}

